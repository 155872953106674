
import React, { useState, useEffect, useReducer } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import EmailConfigService from "../src/api/emailConfigService";
import Header from "../header";
import Footer from "../footer";
import EnquiryConfig from "../Admin/enquiryConfig";
import LoginView from "../Admin/loginView";
import Cookies from "universal-cookie";
import HeaderDashboard from "./adminDashboardHeader";
const EmailConfigServiceObj = new EmailConfigService();
const EmailConfig = () => {
  const [allLists,setAllLists]=useState([]);
  const [Auth, SetAuth] = useState();
  const cookies = new Cookies();
  useEffect (() => {
    SetAuth(cookies.get("userID"));
    getConfigLists()
},[])

const getConfigLists = () => {
  EmailConfigServiceObj.GetEmailConfigLists()
  .then((res) => {
    setAllLists(res.data)
   console.log(res.data)
   setTimeout(() => {
   // $("#preloader").hide();
  }, 1000);
  })  
};


    return Auth ? (
     
        <>
         
         <HeaderDashboard/>
         <div class="main-panel">
        <div class="content">
          <div class="row">
            <div class="col-md-12">
              <div class="card grid">
                <Tabs>
               

                 
                  <TabPanel>
                    <div className="page-inner">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="">
                            <div class="card-header">
                              <div class="d-flex align-items-center">
                                <h4 class="card-title">Inquirys Email Config</h4>
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="table-responsive">
                                <table
                                  className="display table table-striped table-hover"
                                  id="AssociateDisplay"
                                >
                                 
                                  <tbody>
                                  {allLists.length>0 &&
                                  <EnquiryConfig H1BList={allLists} getConfigLists={getConfigLists}/>

                              }   
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </TabPanel>
                
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
     <Footer/>
        </>
       

    ) : (
        <LoginView />
      );
}

export default EmailConfig;