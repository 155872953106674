import CommonHeader from "../CommonHeader";
import Footer from "../footer";
import Header from "../header";
import Service from "./Service";

export default function ServiceMain(){
    return(
        <>
        <Header flag={false}/>
        <CommonHeader Name={"Services"}/>
        <Service/>
        <Footer/>
        <style>
        {`
          .vt-service-link {
            background: -webkit-linear-gradient(#1e72bc 0%, #30CFD0 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: bold;
          }
        `}
      </style>
        </>
    )
}