import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import AboutPage from "./Components/about/about.js"
import "./assets/css/bootstrap.min.css"
import "./assets/css/bootstrap-grid.min.css"
import "./assets/css/bootstrap-reboot.min.css"
import "./assets/css/carousel.min.css"
import "./assets/css/style.css"
import "./assets/css/theme.min.css"
import "./assets/css/responsive.css"
import "./assets/css/transitions.css"
import "./assets/css/animate.css"
import "./assets/css/colors.css"
import "./assets/css/common.css"
// import "./assets/css/flaticon-set.css"
import Home from "./Components/Home/home.js"
import ServiceMain from "./Components/Services/SeviceMain.js"
import ContactMain from "./Components/Contact/ContactMain.js"
import LoginView from "../src/Components/Admin/loginView.js"
import ForgotView from "../src/Components/Admin/forgotPassword.js"
import OtpView from "../src/Components/Admin/checkOtp.js"
import ConfirmPassword from "../src/Components/Admin/changePassword.js"
import Dashboard from "../src/Components/Admin/dashBoard.js"
import EmailTemplateList from "./Components/Admin/emailTemplateList.js"
import EmailTemplateCreate from "./Components/Admin/emailTemplateCreate.js"
import EmailConfig from "./Components/Admin/emailConfig.js"
import EnquiryList from "./Components/Admin/enquiryList.js"
function App() {
   return (
      <Router>
         <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='/about' element={<AboutPage />}></Route>
            <Route path='/services' element={<ServiceMain />}></Route>
            <Route path='/contact' element={<ContactMain />}></Route>
            <Route path="/Admin" element={<LoginView />}></Route>
            <Route path="/OtpView" element={<OtpView />} />
            <Route path="/ChangePassword" element={<ConfirmPassword />} />
            <Route path="/ForgotView" element={<ForgotView />} />
            <Route path="/DashboardView" element={<Dashboard />}></Route>
            <Route path="/EmailTemplateList" element={<EmailTemplateList/>}/>
            <Route path="/EmailTemplateCreate" element={<EmailTemplateCreate/>}/>
            <Route path ="/EmailConfig" element={<EmailConfig/>}/>
            <Route path ="/EnquiryList" element={<EnquiryList/>}/>
         </Routes>
      </Router>
   )
}

export default App
