import CommonHeader from "../CommonHeader";
import Footer from "../footer";
import Header from "../header";
import Contact from "./contact";


export default function ContactMain(){
    return(<>
 

   
    <Header flag={false}/>
    
    <Contact/>
    <Footer/>
    <style>
        {`
          .vt-contact-link {
            background: -webkit-linear-gradient(#1e72bc 0%, #30CFD0 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: bold;
          }
        `}
      </style>
 
    
    </>)
}