import React, { useEffect, useState } from "react";
import logo from "../../assets/images/SMILE Logo-04.png";
import Cookies from "universal-cookie";

const Header = ({ flag }) => {
  const [flag1, setFlag] = useState(true);
  const cookies = new Cookies();

  useEffect(() => {
    if (flag !== undefined) {
      setFlag(flag);
    }
  }, [flag]);

  const logout = () => {
    localStorage.removeItem("token");
    cookies.remove("emailID");
    cookies.remove("userID");
    cookies.remove("userName");
    cookies.remove("token");
    window.location.href = "/Admin";
  };

  return (
    <header className="header header_style1">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-10">
            <div className="logo">
              <a href="/" className="logo-text">
                <img src={logo} alt="#" />
              </a>
            </div>
            <div className="main_menu float-right">
              <div className="menu">
                <ul className="clearfix">
                  <li>
                    <a href="/Dashboardview" className="vt-index-link">
                      Home
                    </a>
                  </li>
                  <li className="dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-user iconcolor"></i>
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a
                        className="dropdown-item"
                        href="/ChangePassword"
                    
                      >
                        <i className="fa fa-key iconcolor" aria-hidden="true"></i>
                        &nbsp;
                        Change Password
                      </a>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={logout}
                      >
                        <i className="fa fa-sign-out iconcolor" aria-hidden="true"></i>
                        &nbsp;
                        Logout
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
