import banner from "../../assets/images/pc-banner-2.png";
export default function HomeHeader(){
return (
    <>
  {/* header */}
  <section id="banner_parallax" className="slide_banner1">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="full">
            <div className="slide_cont">
              <h2>
              We help EMC business to achieve new heights</h2>
              <p>
              We adhering to ISO 17025 Standards by Empowering the process and Ensuring Compliance
              </p>
              <div className="full slide_bt">
                {" "}
                <a className="white_bt bt_main" href="#contact_section">
                  Contact Us
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="full">
            <div
              className="slide_pc_img wow fadeInRight"
              data-wow-delay="1s"
              data-wow-duration="2s"
            >
              <img src={banner}alt="#" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* end header */}
</>

)
}