export default function CommonHeader({Name}){
    return(<>
    
    <section id="banner_parallax" className="inner_page_banner slide_banner1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="full">
                <div className="slide_cont">
                  <h2>{Name}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>)
}