import ScrollToTop from "react-scroll-to-top";
export default function HomeSection1() {
  return (
    <>
      {/* section */}
      <section className="layout_padding padding_top_0">
        <div className="container">
          <div className="row margin_bottom_30">
            {/* featured cont */}
            <div className="col-md-6 cont_theme_blog">
              <div className="full">
                <h3>Project Conversion</h3>
                <p>
                  SMIL-E streamlines the journey from initial enquiry to project fruition. It involves preparing estimates, followed by meticulous review by managers. Once approved, these estimates are transformed into proposals, which are then shared with customers. Upon client approval, proposals evolve into awarded projects. Throughout this process, communication primarily occurs via email exchanges and printed proofs.
                 
                </p>
              </div>
            </div>
            {/* end featured cont */}
            {/* featured image */}
            <div
              className="col-md-6 wow fadeInRight"
              data-wow-delay="0.5"
              data-wow-duration="1s"
            >
              <div className="full">
                {/* <div class="center"><img src="images/ser1.png" alt="#" /></div> */}
                <div className="center">
                  <img
                    src={require("../../assets/images/Resized/01-project.png")}
                    alt="#"
                    className="feature_images_home"
                  />
                </div>
              </div>
            </div>
            {/* end featured image */}
          </div>
          <div className="row margin_bottom_30">
            {/* featured image */}
            <div
              className="col-md-6 wow fadeInLeft"
              data-wow-delay="0.5"
              data-wow-duration="1s"
            >
              <div className="full">
                {/* <div class="center"><img src="images/ser2.png" alt="#" /></div> */}
                <div className="center">
                  <img
                    src={require("../../assets/images/Resized/04-Billing.png")}
                    alt="#"
                    className="feature_images_home"
                  />
                </div>
              </div>
            </div>
            {/* end featured image */}
            {/* featured cont */}
            <div className="col-md-6 cont_theme_blog">
              <div className="full">
                <h3>Billing</h3>
                <p>
                SMIL-E facilitates billing processes for fixed costs estimated at the project's outset and actual costs determined by testing hours utilized in the EMC Chamber. It enables both partial billing and final closing billing, offering flexibility for adjusting costs and hours according to your needs. The system also tracks billing status and history, including maturity periods. Reminder emails prompt stakeholders about upcoming billing due dates.
                  
                </p>
              </div>
            </div>
            {/* end featured cont */}
          </div>
          <div className="row margin_bottom_30">
            {/* featured cont */}
            <div className="col-md-6 cont_theme_blog">
              <div className="full">
                <h3>Business Reports</h3>
                <p>
                SMIL-E boasts highly customizable business reports, allowing users to tailor them to their specific requirements and interests. Users have complete control over report design. Additionally, there's an option to export data to Excel format for further analysis. The dashboard provides graphical insights into business performance, complementing the detailed information presented in reports. Together, the dashboard and reports serve as invaluable tools for business analysis and decision-making.
                  
                </p>
              </div>
            </div>
            {/* end featured cont */}
            {/* featured image */}
            <div
              className="col-md-6 wow fadeInRight"
              data-wow-delay="0.5"
              data-wow-duration="1s"
            >
              <div className="full">
                {/* <div class="center"><img src="images/ser3.png" alt="#" /></div> */}
                <div className="center">
                  <img
                    src={require("../../assets/images/Resized/07-Report.png")}
                    alt="#"
                    className="feature_images_home"
                  />
                </div>
              </div>
            </div>
            {/* end featured image */}
          </div>
        </div>
        <ScrollToTop smooth color="#2b5690" />
      </section>
      {/* end section */}
    </>

  )
}