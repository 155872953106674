import ScrollToTop from "react-scroll-to-top";
export default  function HomeSection2(){
return(
    <>
  {/* section */}
  <section className="layout_padding gradiant_bg cross_layout">
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="information_blogs">
            <div className="imf_icon">
              <img src={require("../../assets/images/icon1.png")} alt="#" />
            </div>
            <div className="imf_head">
              <h3>Process based Features</h3>
            </div>
            <div className="imf_cont">
              <p>
                SMIL-E understands well the EMC business and streamlines the
                testing process stage-by-stage{" "}
              </p>
            </div>
           
          </div>
        </div>
        <div className="col-md-4">
          <div className="information_blogs">
            <div className="imf_icon">
              <img src={require("../../assets/images/icon2.png")} alt="#" />
            </div>
            <div className="imf_head">
              <h3>User Friendly</h3>
            </div>
            <div className="imf_cont">
              <p>
                UI friendliness ensures that layman user can work with the
                application straightaway
              </p>
            </div>
            
          </div>
        </div>
        <div className="col-md-4">
          <div className="information_blogs">
            <div className="imf_icon">
              <img src={require("../../assets/images/icon3.png")} alt="#" />
            </div>
            <div className="imf_head">
              <h3>Support</h3>
            </div>
            <div className="imf_cont">
              <p>
                Required training and support will be provided. Customization
                and enhancements will be provided.
              </p>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <ScrollToTop smooth color="#2b5690" />
  </section>
  {/* end section */}
</>

)
}