import React from "react";
import ScrollToTop from "react-scroll-to-top";
export default function AboutSection() {
  return (
    <section className="layout_padding padding_top_0">
      <div className="container">
        <div className="row margin_bottom_30">
          <div className="full">
            <div className="row">
              <div className="col-sm-12">
                <div className="full text_align_center">
                  <div className="heading_main center_head_border heading_style_1">
                    <h2>About us</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <FeaturedContent
            title="Vaanam as Technologists"
            description="Vaanam Technologies is an emerging leader in the Enterprise Applications and IT Services marketplace.With a strong focus on frameworks for information availability and analysis, Vaanam Technologies has developed Centers of Excellence for each of its service areas, ranging from Cloud Applications to Mobiles Solutions and Business Intelligence."
            image="technology.png"
            flag={1}
          />
        </div>
        <div className="row margin_bottom_30">
          <FeaturedContent
            title="Vaanam as Consultants"
            description="Vaanam consultants have delivered thousands of hours of business analytics, performance management, business intelligence and data warehousing instruction at conferences, seminars, educational institutions and major organizations in a wide range of industries."
            image="consult.png"
            flag={2}
          />
        </div>

        <div className="row margin_bottom_30">
          <FeaturedContent
            title="Vaanam's Vision"
            description="Vaanam has a focused vision in the design and development of BI solutions/frame work, backed by the wide range of domain experience including Banking and Financial Services, Telecom, Healthcare, Manufacturing, Retail & Consumer Products, Travel, Transportation & Hospitality with Business Intelligence tools and technologies."
            image="vision.png"
            flag={3}
          />
        </div>
      </div>
    </section>
  );
}

const FeaturedContent = ({ title, description, image, flag }) => {
  return (
    <>
      {flag == 1 || flag == 3 ? (
        <>
          <div className="col-md-8 cont_theme_blog">
            <div className="full">
              <h3>{title}</h3>
              <p>{description}</p>
            </div>
          </div>

          <div
            className="col-md-4 wow fadeInRight"
            data-wow-delay="0.5"
            data-wow-duration="1s"
          >
            <div className="full">
              <div className="center">
                <img
                  src={require(`../../assets/images/${image}`)}
                  alt="#"
                  className="feature_images"
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div
            className="col-md-4 wow fadeInLeft"
            data-wow-delay="0.5"
            data-wow-duration="1s"
          >
            <div className="full">
              <div className="center">
                <img
                  src={require(`../../assets/images/${image}`)}
                  alt="#"
                  className="feature_images"
                />
              </div>
            </div>
          </div>
          <div className="col-md-8 cont_theme_blog">
            <div className="full">
              <h3>{title}</h3>
              <p>{description}</p>
            </div>
          </div>
          <ScrollToTop smooth color="#2b5690" />
        </>
      )}
    </>
  );
};
