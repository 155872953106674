import React from "react";
import AboutSection from "./content-about";
import Footer from "../footer";
import Header from "../header";
import CommonHeader from "../CommonHeader";

export default function AboutPage() {
  return (
    <div>
      <Header flag={false}/>
      <CommonHeader Name={"About Us"}/>
      <AboutSection />
      <Footer />

      {/* Add styles specific to this component */}
      <style>
        {`
          .vt-about-link {
            background: -webkit-linear-gradient(#1e72bc 0%, #30CFD0 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: bold;
          }
        `}
      </style>
    </div>
  );
}
