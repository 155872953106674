import ScrollToTop from "react-scroll-to-top";
export default function Service(){
    return(
        <>
  {/* section */}
  <section className="layout_padding layer_style">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="full text_align_center">
            <div className="heading_main center_head_border heading_style_1">
              <h2>
                <span>Services</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="row app-features">
        <div className="col-md-4 col-sm-6 col-xs-12">
          <ul className="features-left">
            <li>
              <i className="fa fa-group" />
              <div className="fl-inner">
                <h4>Customer Relationship</h4>
                <p>
                  CRM stores the Customer information along with contacts and
                  company details for the communcation
                </p>
              </div>
            </li>
            <li>
              <i className="fa fa-windows" />
              <div className="fl-inner">
                <h4>Estimation &amp; Estimate Review</h4>
                <p>
                  Estimating the type of Testing and testing hours. Review
                  process by the experts on the estimation
                </p>
              </div>
            </li>
            <li>
              <i className="fa fa-gears" />
              <div className="fl-inner">
                <h4>Project</h4>
                <p>
                  Approved proposals are converted to projects. Projects are
                  taken for the EMC tests.
                </p>
              </div>
            </li>
            <li>
              <i className="fa fa-file-text-o" />
              <div className="fl-inner">
                <h4>Billing Sheet</h4>
                <p>
                  Billing communicates customers for the cost for their testings
                  and reports
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <ul className="features-right">
            <li>
              <i className="fa fa-clock-o" />
              <div className="fr-inner">
                <h4>EMC Chamber Timesheet</h4>
                <p>
                  Captures the actual testing hours along with testing resource
                  information and efforts for the billing.
                </p>
              </div>
            </li>
            <li>
              <i className="fa fa-file-o" />
              <div className="fr-inner">
                <h4>Report Reviews</h4>
                <p>
                  Reports are to be reviewed with hierarchies before releasing
                  to the client
                </p>
              </div>
            </li>
            <li>
              <i className="fa fa fa-wrench" />
              <div className="fr-inner">
                <h4>Instruments &amp; Calibration</h4>
                <p>
                  Instruments information for EMC chambers and its calibration
                  due details.
                </p>
              </div>
            </li>
            <li>
              <i className="fa fa-dashboard" />
              <div className="fr-inner">
                <h4>Dashboard &amp; Business Report</h4>
                <p>
                  Business data in Graphical and textual formats for the
                  analysis
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div
          className="col-md-4 wow fadeInRight"
          data-wow-delay="0.5"
          data-wow-duration="1s"
        >
          <div className="full">
            <div className="center">
              <img
                style={{
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  borderRadius: 15
                }}
                src={require("../../assets/images/application_screen-1.png")}
                alt="#"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ScrollToTop smooth color="#2b5690" />
  </section>
  {/* end section */}
</>

    )
}