import React from "react";
import AxiosServices from "./axiosService";

const Auth = new AxiosServices();

class AuthenticationService {
  getData = async (authentication) => {
    debugger
    return Auth.post("/Login/Authenticate", authentication);
  };

  getOtpData = async (Username) => {
    return Auth.get(`/Login/OTPPassword?userName=${Username}`);
  };

  forgotpassword = async (user) => {
    return Auth.post("/Login/ForgetPassword", user);
  };

  verifyotp = async (data) => {
    return Auth.post("/Login/ForgotpasswordCheckOtp", data);
  };

  Changepassword = async (data) => {
    return Auth.post("/Login/ChangePasswordFromOTP", data);
  };
}

export default AuthenticationService;
