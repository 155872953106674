import React, { useState, useReducer, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import EnquiryService from "../src/api/enquiryService.js";
import ViewInquiry from "../Admin/enquiryView.js";
import Cookies from "universal-cookie";
import LoginView from "../Admin/loginView.js";
import Header from "../header.js";
import Footer from "../footer.js";
import HeaderDashboard from "./adminDashboardHeader";
import MUIDataTable from "mui-datatables";

var EnquiryServiceObj = new EnquiryService();


function EnquiryList() {
  const cookies = new Cookies();
  const [Auth, SetAuth] = useState();
  const [ContactDetails, setContactDetails] = useState([]);

  function refreshPage() {
    window.location.reload(false);
  }
  const [InquiryDetails, setInquiryDetails] = useState([]);
  useEffect(() => {
    SetAuth(cookies.get("userID"));
    EnquiryServiceObj.GetEnquiryList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setInquiryDetails([...res.data]);
      }
    });
  }, []);

  
  const [Model2, setModel2] = useState({
    EnquiryID: "",
    name: "",
    email: "",
    mobile: "",
    companyName: "",
    message: "",
  });
  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "mobile",
      label: "Mobile Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "companyName",
      label: "Company Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "message",
      label: "Message",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "OPTION",
      label: "VIEW",
      options: {
        filter: true,
        sort: false,
        empty: true,
        responsive: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="center">
              <button
                type="button"
                title=""
                className="btn btn-link btn-lg"
                data-original-title="Edit"
                data-toggle="modal"
                data-target="#addRowModal"
                onClick={() => setModel2({
                  name: tableMeta.rowData[0], 
                  email: tableMeta.rowData[2], 
                  mobile: tableMeta.rowData[1], 
                  companyName: tableMeta.rowData[3], 
                  message: tableMeta.rowData[4], 
                })}
              >
                <i className="fa fa-eye"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  return Auth ? (
    <>
      <HeaderDashboard />
      <div class="main-panel">
        <div class="content">
          <div class="row">
            <div class="col-md-12">
              <div class="card grid">
                <Tabs>

                  {/* tab-1 ---> Home */}
                  <TabPanel>
                    <div class="main-panel">
                      <div class="content">
                        <div className="page-inner">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="">
                              
                               
                                  <div class="table-responsive">
                                    <MUIDataTable
                                      data={InquiryDetails}
                                      title="Inquiry Details"
                                      columns={columns}
                                      options={{
                                        responsive: true,
                                        download: false,
                                        print: false,
                                        selectableRows: false,
                                        filter: true,
                                        viewColumns: true,
                                        

                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                   
                    
                      <div
                        class="modal fade"
                        id="addRowModal"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        data-bs-backdrop="static"
                        data-backdrop="static"
                      >
                        <ViewInquiry Model={Model2} />
                      </div>
                   
                  </TabPanel>



                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  ) : (
    <LoginView />
  );
}

export default EnquiryList;
