import React from "react";
import AxiosServices from "../api/axiosService";

const Auth = new AxiosServices();

class EnquiryServices 
{
  Create = async (data) => {
    return await Auth.post(`Enquiry/Create`, data);
  };
  GetEnquiryList = async () => {
    return Auth.get(`Enquiry/EnquiryList`);
  };
}

export default EnquiryServices;