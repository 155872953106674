import ScrollToTop from "react-scroll-to-top";
export default function HomeSection3(){
    return(
        <>
  {/* section */}
  <section className="layout_padding gradiant_bg cross_layout">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="full text_align_center white_fonts">
            <div className="heading_main center_head_border heading_style_1">
              {/* <h2>Easy <span>Steps</span></h2> */}
              <h2>Reports</h2>
              <div className="Report_content">
                Customized reports as per the your requirement and desire
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row step_section">
        <div className="offset-xl-1 col-xl-10 col-md-12">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="step_blog arrow_right_step">
                <div className="step_inner">
                  <i className="fa fa-dashboard" />
                  <br />
                  <p>Graphical Report</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="step_blog">
                <div className="step_inner">
                  <i className="fa fa-file-text-o" />
                  <br />
                  <p>Textual Report</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="step_blog">
                <div className="step_inner">
                  <i className="fa fa-table" />
                  <br />
                  <p>XL Report</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="step_blog">
                <div className="step_inner">
                  <i className="fa fa-edit" />
                  <br />
                  <p>Customized Report</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ScrollToTop smooth color="#2b5690" />
  </section>
</>

    )
}