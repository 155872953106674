import React, { useCallback, useEffect, useState } from "react";
import logo from "../assets/images/SMILE Logo-04.png"
const Header=({flag})=>{
  const [flag1,setFlag]=useState(true);

  useEffect(()=>{
    if(flag!=undefined){
      setFlag(flag);  
    }
  },[])



return(

    <header className="header header_style1">
  <div className="container">
    <div className="row">
      <div className="col-md-9 col-lg-10">
        <div className="logo">
          <a href="/" className="logo-text">
            <img src={logo} alt="#" />
          </a>
        </div>
        <div className="main_menu float-right">
          <div className="menu">
            <ul className="clearfix">
              <li>
                <a href="/" className="vt-index-link" >
                  Home
                </a>
              </li>
              <li>
                <a href="/about" className="vt-about-link" >
                  About
                </a>
              </li>
              <li>
                <a href="/services" className="vt-service-link" >
                  Service
                </a>
              </li>
              {/* <li><a href="screenshots.php" class="vt-screenshots-link">Screenshots</a></li> */}
              {/* <li><a href="pricing.php" class="vt-pricing-link">Pricing</a></li> */}
              <li>
                <a href="/contact" className="vt-contact-link" >
                  Contact
                </a>
              </li>
              {/* <li>
              <a href="http://smile.vaanamtechdemo.com/" target="_blank" className="vt-login-link" >
                  Login
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-lg-2">
        <div className="right_bt">
          <a className="bt_main" href={flag1==true?"#contact_section":"/contact"}>
            Get Support
          </a>{" "}
        </div>
      </div>
    </div>
    </div>
</header>

)
}

export default Header;