import React from 'react';

export default function Footer(){
    return (
        <footer className="footer_style_2" id="footer-Div">
            {/* <div className="footer_top">
                <div className="container">
                    <div className="row">
                       
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 margin_bottom_30">
                            <div className="full width_9">
                                <a href="index.php" className="logo-text">
                                    <img src="images/SMILE Logo-04.png" alt="#" width="115" />
                                </a>
                            </div>
                            <div className="full width_9">
                                <p>consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                            <div className="full width_9">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                </p>
                            </div>
                        </div>

                        
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 margin_bottom_30">
                            <div className="full">
                                <div className="footer_blog_2 width_9">
                                    <h3>Twitter Feed</h3>
                                    <p><i className="fa fa-twitter"></i>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    </p>
                                    <p><i className="fa fa-twitter"></i>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    </p>
                                </div>
                            </div>
                        </div>

                        
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 margin_bottom_30">
                            <div className="full">
                                <div className="footer_blog_2">
                                    <h3>Social</h3>
                                </div>
                            </div>
                            <div className="full">
                                <ul className="footer-links">
                                    <li><a href="#"><i className="fa fa-facebook"></i> 0 Likes</a></li>
                                    <li><a href="#"><i className="fa fa-github"></i> 0+ Projects</a></li>
                                    <li><a href="#"><i className="fa fa-twitter"></i> 0 Followers</a></li>
                                    <li><a href="#"><i className="fa fa-pinterest"></i> 0 Pins</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* Footer bottom */}
            <div className="footer_bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p>© Copyright {new Date().getFullYear()} SMIL-E</p>
                        </div>
                        <div className="col-md-6">
                            <p>Designed & Developed by <a href="https://vaanamtech.com/" target="_blank"><strong><u>Vaanam Technologies</u></strong></a></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
