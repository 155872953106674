import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import ScrollToTop from "react-scroll-to-top";
import LoginView from "../Admin/loginView";
import Header from "../header";
import Footer from "../footer";
import Inquiry from "../../assets/images/inquiry.png"
import EmailConfig from "../../assets/images/emailconfig.png"
import EmailTemp from "../../assets/images/emailtemp.png"
import HeaderDashboard from "./adminDashboardHeader";
export default function Dashboard() {
  const navigate = new useNavigate();
  const cookies = new Cookies();
  const [Auth, SetAuth] = useState();

  useEffect(() => {
    SetAuth(cookies.get("token"));
    
  });

  return Auth ? (
    <>
     

      <HeaderDashboard/>
      <div className="torch-red services-area default-padding bottom-less">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
               
                <h2>Dashboard</h2>
                <div className="devider" />
              </div>
            </div>
          </div>
        </div>
        <div className="container-full">
          <div className="services-items">
            <div className="row">
              {/* Single Item */}
              <div className="col-lg-4 col-md-6 single-item">
                <a href="/EnquiryList">
                  <div className="item">
                    <div className="info">
                    <img className="imginquiry" src={Inquiry} alt="SMILE Logo" />
                      <h4>Inquiry</h4>
                      <div className="bottom">
                        <span>01</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              {/* End Single Item */}
              {/* Single Item */}
              <div className="col-lg-4 col-md-6 single-item">
                <a href="/EmailConfig">
                  <div className="item">
                    <div className="info">
                    <img className="imginquiry" src={EmailConfig} alt="SMILE Logo" />
                      <h4>Email Config</h4>
                      <div className="bottom">
                        <span>02</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              {/* End Single Item */}
              {/* Single Item */}
              <div className="col-lg-4 col-md-6 single-item">
                <a href="/EmailTemplateList">
                  <div className="item">
                    <div className="info">
                    <img className="imginquiry" src={EmailTemp} alt="SMILE Logo" />
                      <h4>Email Template</h4>
                      <div className="bottom">
                        <span>03</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              {/* End Single Item */}

              
            </div>
          </div>
        </div>
      </div>
     <Footer/>
    </>
  ) : (
    <LoginView />
  );
}
