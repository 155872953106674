import React, { useEffect } from "react";
import Service from "../Services/Service.js";
import Header from "../header.js";
import HomeHeader from "./homeHeader.js";
import HomeSection1 from "./homeSection1.js";
import HomeSection2 from "./homeSection2.js";
import AboutSection from "../about/content-about.js";
import HomeSection3 from "./homeSection3.js";
import Contact from "../Contact/contact.js";
import Footer from "../footer.js";
import $ from "jquery";
import ScrollToTop from "react-scroll-to-top";

export default function Home() {

    useEffect(() => {
        if (getCookie('vt_popupCookie') !== 'submited') {
            if (getCookie('vt_popupCookie') !== 'closed') {
                $(".cookie-banner").delay(7000).hide().fadeIn();
            }
        }

        $('#Close_btn').click(function () {
            $('.popup-overlay').fadeOut();
            setCookie('vt_popupCookie', 'closed', 0.00069444444);
            $(".cookie-banner").delay(600).fadeOut();
        });

        $('#Agree_btn').click(function () {
            $('.popup-overlay').fadeOut();
            setCookie('vt_popupCookie', 'submited', 4.16666664);
            $(".cookie-banner").delay(600).fadeOut();
        });
    }, [])

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }


    return (
        <>
            <Header flag={true}/>
            <div className="cookie-banner" style={{ display: "none" }}>
                <p className="cookie-content">
                    This website stores cookies on your computer. These cookies are used to improve your website and provide more personalized services to you, both on this website and through other media.
                    We won't track your information when you visit our site. But in order to comply with your preferences, we'll have to use just one tiny cookie so that you're not asked to make this choice again.
                </p>

                <button id="Agree_btn" className="">Agree</button>
                <button id="Close_btn" className="">Close</button>
            </div>
            <HomeHeader />
            <HomeSection1 />
            <HomeSection2 />
            <Service />
            <HomeSection3 />
            <AboutSection />
            <Contact />
            <Footer />

            <style>
                {`
                    .vt-index-link {
                        background: -webkit-linear-gradient(#1e72bc 0%, #30CFD0 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-weight: bold;
                    }

                    .cookie-banner {
                        position: fixed;
                        bottom: 60px;
                        left: 10%;
                        right: 10%;
                        width: 80%;
                        padding: 5px 14px;
                        background-color: #eee;
                        border-radius: 5px;
                        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
                        z-index: 1;
                    }

                    .close_btn:focus {
                        border: none;
                        outline: none;
                    }

                    .cookie-content {
                        text-align: justify;
                        margin: 0px;
                        margin-right: 5px;
                        color: #5d5a5a;
                    }

                    @media only screen and (max-width: 500px) {
                        .vent-detail {
                            padding-bottom: 50px;
                        }

                        .vent-detail .btn {
                            margin-top: 10px;
                        }

                        .otp_verification_block {
                            margin-bottom: 30px;
                        }

                        .cookie-content {
                            height: 60px;
                            overflow-x: auto;
                        }

                        .cookie-banner {
                            width: 100%;
                            left: 0%;
                        }
                    }

                    #Agree_btn, #Close_btn {
                        outline: none;
                        border: 0px;
                        border: 1px solid #b1acac;
                        margin: 3px;
                        padding: 2px;
                        border-radius: 3px;
                        float: right;
                    }

                    #Agree_btn:active, #Close_btn:active {
                        outline: none;
                    }

                    #Agree_btn:focus, #Close_btn:focus {
                        outline: none;
                    }

                    #Agree_btn:hover, #Close_btn:hover {
                        background-color: #cacaca;
                    }

                    .cookie-banner {
                        transition: 2s;
                    }
                `}
            </style>
            <ScrollToTop smooth color="#2b5690" />
        </>
    );
}
