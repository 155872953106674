import $ from "jquery";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EnquiryServices from "../src/api/enquiryService";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import React, { useRef } from "react";
import ScrollToTop from "react-scroll-to-top";
import Cookies from "universal-cookie";

export default function Contact() {
  var EnquiryServicesObj = new EnquiryServices();
  const [error, setError] = useState({
    name: "",
    email: "",
    mobile: "",
    companyName: "",
    message: "",
  });



  const cookies = new Cookies();
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 750,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  function numberOnly(e) {
    var element = document.getElementById(e);
    element.value = element.value.replace(/[^0-9]/gi, "");
  }

  const name = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.name = "Name is required";
    } else {
      myerror.name = "";
    }
    setError({ ...myerror });
  };

  const email = (e) => {
    var myerror = error;
    // else if(e.target.value == "r'^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$'")
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // (re.test(e.target.value) === false)
    if (e.target.value == null || e.target.value == "") {
      myerror.email = "Email is required";
    } else {
      myerror.email = "";
    }
    setError({ ...myerror });
  };

  const mobile = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.mobile = "Mobile number is required";
    } else {
      myerror.mobile = "";
    }
    setError({ ...myerror });
    numberOnly("mobile");
  };

  const companyName = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.companyName = "Company name is required";
    } else {
      myerror.companyName = "";
    }
    setError({ ...myerror });
  };

  const message = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.message = "Message is required";
    } else {
      myerror.message = "";
    }
    setError({ ...myerror });
  };

  const captchaRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const onSubmit = (res) => {

    res.preventDefault();
    // const token = captchaRef.current.getValue();
    // captchaRef.current.reset();
    var myerror = error;

    var flag = true;
    var data = {
      EnquiryID: 0,
      Name: document.getElementById("name").value,
      Email: document.getElementById("email").value,
      Mobile: document.getElementById("mobile").value,
      CompanyName: document.getElementById("companyName").value,
      Message: document.getElementById("message").value,
    };

    if (data.Name == null || data.Name == "") {
      myerror.name = "Name is required";
      flag = false;
    } else {
      myerror.name = "";
    }

    if (data.Email == null || data.Email == "") {
      myerror.email = "Email is required";
      flag = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.Email)) {
      console.log(data.Email);
      myerror.email = "Please enter the valid email address";
      flag = false;
    } else {
      myerror.email = " ";
    }

    if (data.Mobile == null || data.Mobile == "") {
      myerror.mobile = "Mobile number is required";
      flag = false;
    } else if (data.Mobile.length <= 9) {
      console.log(data.Mobile.length);
      myerror.mobile = "Must have ten-digit";
      flag = false;
    } else {
      myerror.mobile = "";
    }

    if (data.CompanyName == null || data.CompanyName == "") {
      myerror.companyName = "Company name is required";
      flag = false;
    } else {
      myerror.companyName = "";
    }

    if (data.Message == null || data.Message == "") {
      myerror.message = "Message is required";
      flag = false;
    } else {
      myerror.message = "";
    }
    setError({ ...myerror });

    if (flag) {
      // const formData = new FormData();
      console.log(data);
      EnquiryServicesObj.Create(data).then(async (res) => {
        if (res.data == 1) {
          $(".close").trigger("click");

          Toast.fire({
            icon: "success",
            title: "Submitted Successfully",
          });
          setTimeout(function () {
            window.location.reload();
          }, 750);
        } else {
          Toast.fire({
            icon: "error",
            title: "Failed to Send!",
          });
        }
      });
      setIsLoaded(true);
    }
  };

  return (
    <>
      {/* section */}
      <section className="contact_section" id="contact_section">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-xs-12 col-sm-12 col-md-6"
              style={{ padding: 0 }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1384.6136226900994!2d76.99066846776451!3d11.017701839347588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8583a7256f28b%3A0x9110bc8b8e1a5e33!2sVaanam%20Technologies%20(P)%20Limited!5e0!3m2!1sen!2sin!4v1599715682379!5m2!1sen!2sin"
                width="100%"
                height="100%"
                frameBorder={0}
                style={{ border: 0 }}
                allowFullScreen={true}
                aria-hidden={false}
                tabIndex={0}
              />
            </div>
            <div
              className="col-xs-12 col-sm-12 col-md-6"
              style={{ padding: 0 }}
            >
              <div className="full">
                <div className="contact_form white_heading_border">
                  <div className="contact_form_inner">
                    <div className="full_heading white_fonts heading_main heading_style_1">
                      <h2>
                        Contact <span>us</span>
                      </h2>
                    </div>
                    <p>
                      Welcome to Vaanam Technologies, to contact us for a quote
                      or information on any of our products and services please
                      fill out the information request form. We’ll simply
                      contact you to determine whether InterFocus are the right
                      choice for your project.
                    </p>
                    
                    <div className="form-box d-flex justify-content-center"> 
                <form
                  onSubmit={onSubmit}
                  className="contact-form formwidth"
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          className="form-controll"
                          id="name"
                          name="name"
                          placeholder="Name*"
                          type="text"
                          onChange={name}
                        />
                        <span className="text-danger"> {error.name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">

                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          className="form-controll"
                          id="mobile"
                          name="mobile"
                          placeholder="Mobile Number*"
                          type="text"
                          maxLength={10}
                          //onKeyDown={(event) => onkeydown(event)}
                          onChange={mobile}
                        // onChange={phone => numberOnly("phone")}
                        />
                        <span className="text-danger"> {error.mobile} </span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          className="form-controll"
                          id="email"
                          name="email"
                          placeholder="Email*"
                          type="text"
                          onChange={email}
                        />
                        <span className="text-danger"> {error.email} </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          className="form-controll"
                          id="companyName"
                          name="companyName"
                          placeholder="Company Name*"
                          type="text"
                          onChange={companyName}
                        />
                        <span className="text-danger">
                          {" "}
                          {error.companyName}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group comments">
                        <textarea
                          className="form-controll"
                          id="message"
                          name="message"
                          placeholder="Message*"
                          defaultValue={""}
                          onChange={message}
                        />
                        <span className="text-danger"> {error.message} </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 d-flex justify-content-center">
                      
                        
                        {!isLoaded ? (
                          <button type="submit" name="submit" id="submit" className="btn btn-success smbtn">
                          Send Message <i className="fa fa-paper-plane" />
                        </button>
                        ) : (
                          <button
                            className="smbtn"
                            id="submit"
                            type="submit"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm  "
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </button>
                        )}
                     
                    </div>
                  </div>
                  {/* Alert Message */}
                  <div className="col-lg-12 alert-notification">
                    <div id="message" className="alert-msg" />
                  </div>
                </form>
              </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ScrollToTop smooth color="#2b5690" />
          </div>
      
      </section>
      {/* end section */}
      <ToastContainer />
      

    </>
  );
}
